import React, { useState, useEffect } from "react";
import "./BurgerMenu.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";

import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import useLanguage from "../../../hooks/useLanguage";
function BurgerMenu(props) {
  const navigate = useNavigate();

  const { lang, toggleLanguage } = useLanguage();

  return (
    <div className="Main_Burger_div">
      <div className="Burger_container">
        <div className="close_burger" onClick={() => props.setIsShown(false)}>
          <AiOutlineCloseCircle size={30} className="close_burger_icon"/>
        </div>
        {/* {islogedin && (
          <div className="Burger_logedin_container">
            <div className="profile_image_container">
              <img
                src={process.env.REACT_APP_API + "/user/" + userinfo.img_url}
                alt="profile"
                className="profile_image"
              />
            </div>
            <div className="Burger_logedin_text"> {userinfo.full_name}</div>
          </div>
        )} */}
        <div className="Burger_logedin_container">
          <div className="profile_image_container">
            <img
                src={logo}
                alt="profile"
                className={'logo-burger-menu'}
            />
          </div>

        </div>

        <div className="Burger_nav_container">
          <div className="Burger_nav_item" onClick={() => navigate("/")}>
            <div className="Burger_nav_item_text">
              {" "}
              {lang == "en" && <div> Home</div>}
              {lang == "ar" && <div> الصفحة الرئيسية</div>}
            </div>
            <div className="divider"/>
          </div>
          <div
              className="Burger_nav_item"
              onClick={() => navigate("/majordetails")}
          >
            <div className="Burger_nav_item_text">
              {" "}
              {lang == "en" && <div> Know your major</div>}
              {lang == "ar" && <div> استكشف اختصاصك</div>}
            </div>
            <div className="divider"/>
          </div>
          {/* <div className="Burger_nav_item">
            <div className="Burger_nav_item_text">Companies</div>
            <div className="divider" />
          </div> */}
          <div
              className="Burger_nav_item"
              onClick={() => navigate("/schoolsmap")}
          >
            <div className="Burger_nav_item_text">
              {" "}
              {lang == "en" && <div>Schools maps</div>}
              {lang == "ar" && <div>خارطة المدارس</div>}
            </div>
            <div className="divider"/>
          </div>
          <div className="Burger_nav_item" onClick={() => navigate("/tvet")}>
            <div className="Burger_nav_item_text">
              {" "}
              {lang == "en" && <div>Understand TVET</div>}
              {lang == "ar" && <div>مسار التعليم المهني</div>}
            </div>
            <div className="divider"/>
          </div>
          <div className="Burger_nav_item">
            <div className="Burger_nav_item_text">
              {" "}
              <a href="https://jobs.mehnati.org/">Job Portal</a>
            </div>
            <div className="divider"/>
          </div>
          <div className="Burger_nav_item">
            <div className="Burger_nav_item_text">
              {" "}
              <a href="https://lms.mehnati.org/">LMS</a>
            </div>
            <div className="divider"/>
          </div>

          <div className="header_translation ">
            <div
                onClick={toggleLanguage}
                className={
                  lang == "ar"
                      ? "translation_section translation_section_arabic"
                      : "translation_section"
                }
            >
              <span
                  className={
                    lang === "en"
                        ? "language-selected active-lang"
                        : "language-selected "
                  }
              >
                EN
              </span>
              |
              <span
                  className={
                    lang === "ar"
                        ? "language-selected active-lang"
                        : "language-selected "
                  }
              >
                ع
              </span>
            </div>
          </div>

          {/* {islogedin ? (
            <div className="Burger_nav_item" onClick={handleLogout}>
              <div className="Burger_nav_item_text">Log out</div>
              <div className="divider" />
            </div>
          ) : (
            <div className="Burger_nav_item" onClick={() => navigate("/login")}>
              <div className="Burger_nav_item_text">Login</div>
              <div className="divider" />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
