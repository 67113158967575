import { useState, useEffect } from "react";
import i18n from "i18next";

const useLanguage = () => {
    const [lang, setLang] = useState(localStorage.getItem("lang") || i18n.language);

    const updateLanguage = (language) => {
        i18n.changeLanguage(language);
        document.body.dir = language === "ar" ? "rtl" : "ltr";
        document.documentElement.setAttribute("lang", language);
        setLang(language);
        localStorage.setItem("lang", language);
    };

    useEffect(() => {
        updateLanguage(lang);
    }, []);

    const toggleLanguage = () => {
        updateLanguage(lang === "en" ? "ar" : "en");
    };

    return { lang, toggleLanguage };
};

export default useLanguage;
